import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import FinancialService, {
  BillDetails,
  DiscountLetterDTO,
  FileData,
} from '@services/FinancialService';

import { BillListRouter } from '@flows/private/financial/pages/List/router';
import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';

interface BillStateContextData {
  discountLettersState: UseQueryResult<DiscountLetterDTO[]>;
  coparticipationFilesState: UseQueryResult<FileData[]>;
  billDetailsState: UseQueryResult<BillDetails>;
  billingFileState: UseQueryResult<FileData>;
  refreshBillStates: () => void;
}

export const BillStateContext = createContext<BillStateContextData>(
  {} as BillStateContextData,
);

function BillStateProvider({ children }: PropsWithChildren<{}>) {
  const { enqueueSnackbar } = useSnackbar();
  const [params] = useSearchParams();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const financialService = useMemo(() => new FinancialService(), []);

  const year = useMemo(
    () => params.get('year') ?? new Date().getFullYear().toString(),
    [],
  );

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled =
    Boolean(id) &&
    Boolean(companyId) &&
    Boolean(year) &&
    Boolean(partnerCompany);

  const billDetailsState = useQuery({
    queryKey: ['billDetailsState', id, companyId, partnerCompany],
    queryFn: () =>
      financialService.getBill({
        billNumber: id,
        companyId,
        year,
      }),
    enabled: isEnabled,
    retry: 2,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
      navigate(BillListRouter.path);
    },
  });

  const coparticipationFilesState = useQuery<FileData[]>({
    queryKey: ['coparticipationFilesState', id],
    queryFn: () => {
      if (!billDetailsState.data) return [];

      return Promise.all(
        billDetailsState.data.coparticipationAnalytical.map(async item => {
          const path = await financialService.getCoPaymentReport({
            personNumber: billDetailsState.data.personNumber,
            billingExtractId: item.billingExtractId,
            fileName: item.fileName,
          });

          return {
            fileName: item.fileName,
            path,
          };
        }),
      );
    },
    enabled:
      billDetailsState.isSuccess &&
      billDetailsState.data.coparticipationAnalytical.length > 0,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const billingFileState = useQuery<FileData>({
    queryKey: ['billingFileState', id],
    queryFn: async () => {
      if (!billDetailsState.data) return {} as FileData;

      const path = await financialService.getBilling({
        billingNumber: billDetailsState.data?.billingNumber,
        personNumber: billDetailsState.data?.personNumber,
        companyId,
      });

      return {
        fileName: 'Boleto',
        path,
      };
    },
    enabled: billDetailsState.isSuccess,
    networkMode: 'offlineFirst',
    refetchOnWindowFocus: false,
    retryOnMount: false,
    retry: false,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const discountLettersState = useQuery({
    queryKey: ['discountLettersState', id],
    queryFn: () =>
      financialService.getDiscountLetters({
        companyId,
        invoiceNumber: billDetailsState.data?.invoice?.number,
      }),
    enabled: billDetailsState.isSuccess,
  });

  const refreshBillStates = () => {
    coparticipationFilesState.remove();
    billDetailsState.remove();
    discountLettersState.remove();

    coparticipationFilesState.refetch();
    billDetailsState.refetch();
    discountLettersState.refetch();
  };

  const valueBillStateContextProvider = useMemo(
    () => ({
      coparticipationFilesState,
      refreshBillStates,
      billingFileState,
      billDetailsState,
      discountLettersState,
    }),
    [
      coparticipationFilesState,
      refreshBillStates,
      billingFileState,
      billDetailsState,
      discountLettersState,
    ],
  );

  return (
    <BillStateContext.Provider value={valueBillStateContextProvider}>
      {children}
    </BillStateContext.Provider>
  );
}

function useBillState() {
  const context = useContext(BillStateContext);

  if (!context) {
    throw new Error('useBillState must be used within BillStateProvider');
  }

  return context;
}

export { useBillState, BillStateProvider };
