import { Stack, Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

import Logo from '@hapvida/hapvida-core-components/src/assets/images/hapvida-ndi-logo-new.svg';
import { parseCurrency } from '@hapvida/hapvida-core-components/src/utils';

import SignatureImg from '@assets/images/signature_discount_letter.png';

import {
  LetterTable,
  TdSubHeader,
  Th,
  Td,
  DiscriminationTd,
  Footer,
  PrintImg,
} from './styles';
import { DiscountLetterPageProps } from './types';

export const DiscountLetterPage = ({ letter }: DiscountLetterPageProps) => {
  const {
    address = '',
    city = '',
    companyName = '',
    description1 = '',
    description2 = '',
    description3 = '',
    description4 = '',
    description5 = '',
    description6 = '',
    description7 = '',
    discountValue = '',
    document = '',
    dueDate = '',
    invoiceNumber = '',
    invoiceValue = '',
    letterNumber = '',
    netValue = '',
    partnerCompanyCode = '',
  } = letter;
  const todayDate = new Date().toLocaleDateString('pt-BR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return (
    <Stack p="32px" gap="24px" bgcolor={COLORS.WHITE}>
      <Stack gap="12px">
        <Stack flexDirection="row" justifyContent="space-between">
          <img src={Logo} alt="NDI" />

          <Typography fontSize={24} color={COLORS.BLACK}>
            N° {letterNumber}
          </Typography>
        </Stack>
        <Typography color={COLORS.BLACK} fontSize={32} alignSelf="center">
          Carta de Desconto
        </Typography>
        <Typography fontSize={24} color={COLORS.BLACK} alignSelf="flex-end">
          Fortaleza, {todayDate}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="caption" fontSize={18} color={COLORS.BLACK}>
          Hapvida Assistência Médica
        </Typography>
        <Typography variant="caption" fontSize={18} color={COLORS.BLACK}>
          Av. Heráclito Graça, 408, Centro, Fortaleza - CE
        </Typography>
        <Typography variant="caption" fontSize={18} color={COLORS.BLACK}>
          CNPJ: 63.664.097/0001-93
        </Typography>
        <Typography variant="caption" fontSize={18} color={COLORS.BLACK}>
          INSCRIÇÃO NO CPBS: 106.634-3
        </Typography>

        <Typography
          variant="caption"
          fontSize={18}
          color={COLORS.BLACK}
          my="12px"
        >
          À
        </Typography>

        <Typography variant="caption" fontSize={18} color={COLORS.BLACK}>
          {companyName}
        </Typography>
        <Typography variant="caption" fontSize={18} color={COLORS.BLACK}>
          {address}
        </Typography>
        <Typography
          variant="caption"
          fontSize={18}
          color={COLORS.BLACK}
          textTransform="uppercase"
        >
          {city}
        </Typography>
        <Typography
          variant="caption"
          fontSize={18}
          color={COLORS.BLACK}
          textTransform="uppercase"
        >
          {document}
        </Typography>

        <Typography
          variant="caption"
          fontSize={18}
          color={COLORS.BLACK}
          my="12px"
        >
          {partnerCompanyCode}
        </Typography>

        <Typography
          variant="caption"
          fontSize={18}
          color={COLORS.BLACK}
          my="12px"
        >
          Prezado (s) Senhor (es)
        </Typography>
      </Stack>
      <LetterTable>
        <thead>
          <tr>
            <Th colSpan={5}>NF para efeito de desconto</Th>
            <Th style={{ whiteSpace: 'normal' }}>Discriminação</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TdSubHeader>NF</TdSubHeader>
            <TdSubHeader>Vencimento</TdSubHeader>
            <TdSubHeader>Valor</TdSubHeader>
            <TdSubHeader>Desconto</TdSubHeader>
            <TdSubHeader>Valor líquido</TdSubHeader>
            <DiscriminationTd rowSpan={2}>
              <Stack justifyContent="space-between" height="100%" gap="8px">
                <Typography color={COLORS.BLACK} lineHeight="normal">
                  {description1} {description2} {description3} {description4}
                </Typography>
                <Typography color={COLORS.BLACK} lineHeight="normal">
                  {description5} {description6} {description7}
                </Typography>
                <Typography lineHeight="normal">
                  Obs: Esse crédito poderá ser utilizado em outras Obrigações.
                </Typography>
              </Stack>
            </DiscriminationTd>
          </tr>
          <tr>
            <Td>{invoiceNumber}</Td>
            <Td>{dueDate}</Td>
            <Td>{parseCurrency(Number(invoiceValue))}</Td>
            <Td>{parseCurrency(Number(discountValue))}</Td>
            <Td>{parseCurrency(Number(netValue))}</Td>
          </tr>
        </tbody>
      </LetterTable>

      <Stack gap="16px">
        <Typography variant="text" color={COLORS.BLACK}>
          Para evitar qualquer canção fiscal, solicitamos acusarem o recebimento
          desta, na cópia que acompanha devendo a via de V.S. ª ficar arquivada
          juntamente com a NF em questão.
        </Typography>
        <Typography variant="text" color={COLORS.BLACK}>
          Bem mais para o momento, subscrevemo-nos
        </Typography>
        <Typography variant="text" color={COLORS.BLACK}>
          Atenciosamente,
        </Typography>
      </Stack>

      <Footer>
        <Stack width="50%" alignItems="center">
          <PrintImg src={SignatureImg} alt="NDI" />
          <Typography
            variant="text"
            borderTop="1px solid black"
            width="100%"
            textAlign="center"
            color={COLORS.BLACK}
          >
            carimbo e assinatura do(a) gerente Hapvida
          </Typography>
        </Stack>
        <Typography
          borderTop="1px solid black"
          width="50%"
          variant="text"
          textAlign="center"
          color={COLORS.BLACK}
        >
          Local e data
        </Typography>
      </Footer>
    </Stack>
  );
};
