import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { SimpleFile, DataGrid } from '@hapvida/hapvida-core-components';

import { DataGridItem } from '@hapvida/hapvida-core-components/src/components/DataGrid';

import { BillDetails, FileData } from '@services/FinancialService';

import { FileGroupProps } from '@utils';

import { useBillData } from '../../hooks';
import {
  ButtonLink,
  DiscountLink,
  InvoiceButton,
  OperationalCostModal,
} from './components';

interface BillDetailsCardProps {
  billingFile?: FileData;
  bill: BillDetails;
  reportFiles: FileGroupProps;
}

export function BillDetailsCard({
  billingFile,
  bill,
  reportFiles,
}: BillDetailsCardProps) {
  const { commonData } = useBillData(bill);
  const [openOperationalCostModal, setOpenOperationalCostModal] =
    useState(false);
  const toggleModal = useCallback(
    () => setOpenOperationalCostModal(!openOperationalCostModal),
    [openOperationalCostModal],
  );

  const handleOpenFile = (file?: FileData) => {
    if (file?.path) window.open(file.path, '_blank');
  };

  const handleOpenFiles = (files?: FileData[]) => {
    files?.forEach(file => handleOpenFile(file));
  };
  const { csvFiles, pdfFiles, txtFiles } = reportFiles;

  const isOperationalCost = bill.billType === 'OperationalCost';

  const operationalCostRow = {
    label: 'Relatório analítico',
    xs: 3,
    value: (
      <ButtonLink
        onClick={toggleModal}
        text="Acessar relatório"
        iconName="link"
      />
    ),
  };

  const reportFilesRows = [
    {
      label: 'Relatório analítico (PDF)',
      xs: 3,
      value:
        pdfFiles && pdfFiles.length > 0 ? (
          <ButtonLink
            onClick={() => handleOpenFiles(pdfFiles)}
            text="Relatório.pdf"
          />
        ) : (
          <Typography variant="body2">-</Typography>
        ),
    },
    {
      label: 'Relatório analítico (CSV)',
      xs: 3,
      value:
        csvFiles && csvFiles.length > 0 ? (
          <ButtonLink
            onClick={() => handleOpenFiles(csvFiles)}
            text="Relatório.csv"
          />
        ) : (
          <Typography variant="body2">-</Typography>
        ),
    },
    {
      label: 'Relatório analítico (TXT)',
      xs: 3,
      value:
        txtFiles && txtFiles.length > 0 ? (
          <ButtonLink
            onClick={() => handleOpenFiles(txtFiles)}
            text="Relatório.txt"
          />
        ) : (
          <Typography variant="body2">-</Typography>
        ),
    },
  ];

  const newVisibleRows = isOperationalCost
    ? [operationalCostRow]
    : reportFilesRows;

  const gridRows: DataGridItem[] = [
    ...commonData,
    {
      label: 'Boleto',
      xs: 3,
      value: billingFile ? (
        <SimpleFile
          onDownload={() => handleOpenFile(billingFile)}
          filename={billingFile.fileName}
        />
      ) : (
        <Typography variant="body2">-</Typography>
      ),
    },
    {
      label: 'Nota Fiscal',
      xs: 3,
      value: <InvoiceButton bill={bill} />,
    },
    {
      label: 'Carta de desconto',
      xs: 6,
      value: <DiscountLink />,
    },
    ...newVisibleRows,
  ];

  return (
    <>
      <DataGrid rows={gridRows} gridContainerProps={{ rowSpacing: 3 }} />
      <OperationalCostModal
        openModal={openOperationalCostModal}
        toggleModal={toggleModal}
      />
    </>
  );
}
