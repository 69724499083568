import { JSX, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import {
  Header as CoreHeader,
  UserMenu,
  Modal,
  Icon,
  useSidebar,
} from '@hapvida/hapvida-core-components';

import { useAuth } from '@hooks';
import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';

import { SelectedCompany, SelectedPartnerCompany } from './components';
import { MenuButton, MobileHiddenStack } from './styles';

interface HeaderProps {
  mainContent: JSX.Element;
}
export function Header({ mainContent }: Readonly<HeaderProps>) {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const handleLogoutConfirmation = () =>
    setShowLogoutConfirmation(!showLogoutConfirmation);
  const { user, logOut } = useAuth();
  const { toggleDrawer } = useSidebar();
  const currentPath = window.location.pathname;

  const routeIncludesBackofficeUsers = Boolean(
    currentPath?.includes(BackofficeUserListRouter.path),
  );

  const showHeaderContent = !routeIncludesBackofficeUsers;

  return (
    <>
      <CoreHeader mainContent={mainContent}>
        <Stack flex={1}>
          <Grid display={{ sm: 'none' }}>
            <MenuButton
              variant="text"
              startIcon={<Icon name="drawer" />}
              onClick={toggleDrawer}
            >
              Menu
            </MenuButton>
          </Grid>
          {showHeaderContent && (
            <MobileHiddenStack>
              <SelectedCompany />
              <SelectedPartnerCompany />
            </MobileHiddenStack>
          )}
        </Stack>
        <Stack>
          <UserMenu
            username={user?.name}
            role={user?.profile}
            onLogOut={handleLogoutConfirmation}
          />
        </Stack>
      </CoreHeader>

      <Modal
        icon="alert"
        secondaryButtonText="Fechar"
        mainButtonText="Sair mesmo assim"
        title="Atenção! As informações não finalizadas poderão ser perdidas."
        mainAction={logOut}
        open={showLogoutConfirmation}
        handleCloseModal={handleLogoutConfirmation}
      >
        <Typography variant="body1" fontSize={14}>
          Tem certeza que desejar sair?
        </Typography>
        <Typography variant="body1" fontSize={14}>
          Ao sair, informações não finalizadas poderão ser perdidas.
        </Typography>
      </Modal>
    </>
  );
}
